a {
    font-family: Garamond, Baskerville, "Baskerville Old Face", "Hoefler Text", "Times New Roman", serif;
    text-decoration: none;
}

a:active {
    color: #541219;
}

a:hover {
    color: #541219;
}

a:link {
    color: #565756;
}

a:visited {
    color: #565756;
}

h3 {
    color: #565756;
    font-size: 1.2em;
    margin: 0;
}

h4 {
    color: #565756;
    font-size: 0.8em;
    margin: 0;
}

input[type=text] {
    background-color: transparent;
    border-bottom: 0.1em solid rgba(86, 87, 86, 1);
    border-left: 0.1em solid rgba(86, 87, 86, 1);
    border-right: 0;
    border-top: 0;
    color: rgba(86, 87, 86, 1);
}

input[type=text]:focus {
    border-bottom: 0.1em solid rgba(253, 253, 253, 0.5);
    border-bottom: 0.2em solid rgba(84, 18, 25, 1);
    border-left: 0.2em solid rgba(84, 18, 25, 1);
    border-right: 0;
    border-top: 0;
    color: rgba(86, 87, 86, 1);
}

.alignRight {
    text-align: right;
}

.appbody {
    background-attachment: fixed;
    background-color: #fdfdfd;
    background-position: left center;
    background-repeat: no-repeat;
    text-align: justify;
}

.bodyBtn {
    background-color: rgba(0, 0, 0, 0);
    padding: 0.8em 1.2em;
}

.bodyBtn:hover {
    background-color: rgba(253, 253, 253, 0.8);
}

.boldText {
    font-weight: bold;
}

.border {
    border: 0.02em solid rgba(86, 87, 86, 0.8);
    border-radius: 0.45em;
}

.border:hover {
    border: 0.02em solid rgba(84, 18, 2, 1);
}

.bottom {
    bottom: 0;
    position: fixed;
}

.bottomHalfHalfLeft {
    bottom: 0.5em;
    left: 3em;
    position: absolute;
}

.bottomHalfLeft {
    bottom: 0.5em;
    left: 3.5em;
    position: absolute;
}

.bottomLeft {
    bottom: 0.5em;
    left: 0.5em;
    position: absolute;
}

.bottomRight {
    bottom: 0.5em;
    right: 0.5em;
    position: absolute;
}

.bottomRightWithinContainer {
    bottom: 0.5em;
    right: 0.5em;
    position: absolute;
}

.btnMargin {
    margin: 0.8em;
}

.btnBorder {
    border: 0.1em solid rgba(86, 87, 86, 0.8);
    border-radius: 0.2em;
}

.btnBorder:hover {
    background-color: rgba(86, 87, 86, 0.05);
    border: 0.1em solid rgba(84, 18, 25, 1);
}

.btnPositionGallery {
    bottom: 50vh;
    position: fixed;
}

.centerMe {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.centerMeHorizontally {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.centerText {
    text-align: center;
}

.contactButton {
    height: 2em;
}

.coverPicSize {
    max-height: 12em;
}

.coverPicSizeDetails {
    max-height: 21em;
}

.cursorPointer {
    cursor: pointer;
}

.customFont {
    font-family: Garamond, Baskerville, "Baskerville Old Face", "Hoefler Text", "Times New Roman", serif;
}

.displayBlock {
    display: block;
}

.foreground101 {
    z-index: 101;
}

.fullWidth {
    width: 100%;
}

.halfWidth {
    width: 100%;
}

.heightInherit {
    min-height: inherit;
}

.heightFullScreen {
    min-height: 100vh;
}

.iconSize {
    min-height: 1.2em;
    max-height: 2em;
}

.iconWidth {
    min-width: 1.2em;
    max-width: 2em;
}

.inlineFlex {
    display: inline-flex;
}

.invisible {
    display: none;
}

.left {
    left: 0.5em;
}

.lightText {
    color: rgba(84, 18, 25, 1);
    display: flex;
    font-weight: 900;
    text-align: justify;
}

.mainMenuOption {
    font: inherit;
    margin: 0.2em 0.8em;
    text-align: center;
    text-decoration: none;
}

.mainMenuOptionsContainer {
    max-width: 12em;
}

.marginBottom {
    margin-bottom: 2em;
}

.marginBottomSmall {
    margin-bottom: 0.5em;
}

.marginBottomLarge {
    margin-bottom: 6em;
}

.marginSides {
    margin: 0 1.5em;
}

.marginSmall {
    margin: 0.5em;
}

.marginRightSmall {
    margin: 0.5em;
}

.marginTop {
    margin-top: 2em;
}

.marginTopLarge {
    margin-top: 6em;
}

.marginPicture {
    margin: 2em;
}

.maxWidthForReadibility {
    display: inline-block;
    max-width: 40em;
}

.menuButton {
    background-color: rgba(253, 253, 253, 0.8);
    border: 0.1em solid rgba(86, 87, 86, 0);
    border-radius: 0.2em;
    height: 3em;
    width: 3em;
}

.menuButton:hover {
    background-color: rgba(86, 87, 86, 0.05);
    border: 0.1em solid rgba(84, 18, 25, 1);
}

.noBullets {
    list-style-type: none;
    padding: 0;
}

.noStyle {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    outline: rgba(0, 0, 0, 0);
}

.noStyle:focus-visible {
    background-color: rgba(0, 0, 0, 0);
    border: none;
}

.opacity05 {
    opacity: 0.5;
}

.paddingSmall {
    padding: 0.5em;
}

.paddingBottom {
    padding-bottom: 2em;
}

.paddingLeft {
    padding-left: 1em;
}

.paddingRight {
    padding-right: 1em;
}

.paddingSides {
    padding-left: 1em;
    padding-right: 1em;
}

.pictureSize {
    max-height: 65vh;
    max-width: 90vw;
}

.positionRelative {
    position: relative;
}

.redText {
    color: rgba(84, 18, 25, 1);
}

.redTextOnHover {
    color: rgba(86, 87, 86, 1);
    font-size: 1.5em;
}

.redTextOnHover:hover {
    color: rgba(84, 18, 25, 1);
}

.right {
    right: 0.5em;
}

.rightText {
    text-align: right;
}

.roundCorners {
    border-radius: 25%;
}

.sendOrderBtn {
    border: 0.05em solid rgba(147, 148, 147, 0.8);
}

.sitelogo {
    border: 0.02em solid rgba(84, 18, 25, 1);
    border-radius: 30em;
    height: 40vmin;
    pointer-events: none;
}

.textLightGrey {
    color: rgba(147, 148, 147, 1);
}

.textSmall {
    font-size: 0.8em;
}

.Toastify__toast {
    background-color: rgba(253, 253, 253, 0.98);
}

.Toastify__progress-bar {
    --toastify-color-progress-light: linear-gradient( to right, #efefef, #fdfdfd );
}

.topRightCorner {
    position: absolute;
    right: 0;
    top: 0;
}

.visible {
    display: flex;
}

.width12 {
    width: 12em;
}

.width75 {
    width: 75%;
}

#shoppingCart {
    background-color: rgba(253, 253, 253, 0.93);
    border: 0.05em solid rgba(86, 87, 86, 0.93);
    border-radius: 1.2em;
    padding: 1.2em;
    position: relative;
}

#welcomeHeader {
    margin-top: 1em;
}

@media (max-width: 480px) {
    h1 {
        font-size: 1.5em;
    }

    .Toastify__toast {
        left: 50%;
    }

    #shoppingCart {
        padding: 0.25em;
        width: 100%;
    }
}

@media (max-width: 1000px) {
    .maxWidthForReadibility {
        display: inline-block;
        max-width: 40em;
        padding: 0 1em;
    }
}

/*COLOR TABLE
GREY (dark -> light):
outer space - 474847 - 71, 72, 71
davy's grey - 565756 - 86, 87, 86
spanish grey - 939493 - 147, 148, 147
isabelline - efefef - 239, 239, 239
baby powder - fdfdfd - 253, 253, 253

RED:
dark sienna - 360C10 - 54, 12, 16
dark scarlet - 541219 - 84, 18, 25
*/